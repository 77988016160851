<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Produksi</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY HH:mm:ss"
                                    type="datetime"
                                    placeholder=""
                                    value-type="YYYY-MM-DD HH:mm:ss"
                                    style="width:100%"
                                    @change="onChangeDateFrom"
                                >
                                </date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY HH:mm:ss"
                                    type="datetime"
                                    placeholder=""
                                    value-type="YYYY-MM-DD HH:mm:ss"
                                    style="width:100%"
                                    @change="onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        
                        <CRow class="pb-0">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Downtime</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <input id="Downtime" v-model="Downtime" class="font-weight-bold" @change="onChangeDowntime()" type="checkbox"/>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow class="pb-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Mesin</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Machine" class="font-weight-bold" @input="onChangeMachine()" :options="MachineData" v-model="Machine" />
                                <label id="errorMachine" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Mesin</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="MachineType" class="font-weight-bold" @input="onChangeMachineType()" :options="MachineTypeData" v-model="MachineType" />
                                <label id="errorMachineType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>     

                        <CRow class="pb-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Detail</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="TypeDetail" class="font-weight-bold" @input="onChangeTypeDetail()" :options="TypeDetailData" v-model="TypeDetail" />
                                <label id="errorTypeDetail" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        
                        <CRow class="pb-0">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Item</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Item" class="font-weight-bold" @input="onChangeItem()" :options="ItemData" v-model="Item" />
                                <label id="errorItem" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <div style="border:1px solid lightgrey;padding:10px" class="pb-3">
                            <CRow>
                                <CCol class="sm-1">
                                    <CRow>
                                        <CCol class="sm-2 col-8">
                                            <label class="form-label font-weight">Total Berat Barang Jadi</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-2">
                                            <label class="form-label font-weight">{{TotalWeightProductionResult}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-2 col-8">
                                            <label class="form-label font-weight">Total Berat Waste</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-2">
                                            <label class="form-label font-weight">{{TotalWeightWaste}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-2 col-8">
                                            <label class="form-label font-weight">Total Berat Loss</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-2">
                                            <label class="form-label font-weight">{{TotalWeightLoss}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-2 col-8">
                                            <label class="form-label font-weight">Total Berat Bahan Baku</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-2">
                                            <label class="form-label font-weight">{{TotalWeightUsedMaterial}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-2 col-8">
                                            <label class="form-label font-weight">Total Perencanaan Produksi</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-2">
                                            <label class="form-label font-weight">{{TotalQtyProductionPlanning}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-2 col-8">
                                            <label class="form-label font-weight">Total Barang Jadi</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-2">
                                            <label class="form-label font-weight">{{TotalQtyProductionResult}}</label>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>

                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                    </CCol>
                </CRow>

                <hr>               
                <CRow>  
                    <CCol>
                        <production-report-grid 
                            ref="productionReportGrid"
                            :key="gridReload" 
                            :dateFrom="DateFromChangest" 
                            :dateTo="DateToChangest"
                            :machine="MachineChangest"
                            :machineType="MachineTypeChangest"
                            :typeDetail="TypeDetailChangest"
                            :item="ItemChangest"
                            :downtime="DowntimeChangest"
                         />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import datePicker from 'vue2-datepicker';
import moment from 'moment';
import { report, sweet_alert } from '../../../infrastructure/constant/variable';

import ProductionReportGrid from '../ProductionReport/Grid/ProductionReportGrid.vue';
import ProductionReportServices from '../ProductionReport/Script/ProductionReportService.js';

export default {
    name: 'ProductionReport',
    components: {
        'production-report-grid': ProductionReportGrid,
        'date-picker': datePicker,
    },
    data(){
        return{
            DateTypeData: [],
            DateType: report.default_date,

            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            DateFrom: '',
            DateTo: '',

            Downtime: true,
            DowntimeChangest: true,
            
            Machine: null,
            MachineData: [],
            MachineChangest: null,
            
            MachineType: null,
            MachineTypeData: [],
            MachineTypeChangest: null,
            
            TypeDetail: null,
            TypeDetailData: [],
            TypeDetailChangest: null,
            
            Item: null,
            ItemData: [],
            ItemChangest: null,

            TotalWeightProductionResult: '',
            TotalWeightWaste: '',
            TotalWeightLoss: '',
            TotalWeightUsedMaterial: '',
            TotalQtyProductionPlanning: '',
            TotalQtyProductionResult: '',

            gridReload: 0,
        }
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.MachineData = await ProductionReportServices.getMachineQuery();
        this.Machine = null;
        
        this.MachineTypeData = await ProductionReportServices.getMachineTypeQuery();
        this.MachineType = null;
        
        this.TypeDetailData = await ProductionReportServices.getTypeDetailQuery();
        this.TypeDetail = null;
        
        this.ItemData = [];
        this.Item = null;

        this.onChangeDateType();
    },
    methods: {
        reload(){
            this.gridReload++;
        },
        async getTotalCount(variables){            
            var data = await ProductionReportServices.getProductionReportCountQuery(variables);
            
            this.TotalWeightProductionResult = data.total_weight_production_result;
            this.TotalWeightWaste = data.total_weight_waste_result;
            this.TotalWeightLoss = data.total_weight_loss_result;
            this.TotalWeightUsedMaterial = data.total_weight_used_material;
            this.TotalQtyProductionPlanning = data.total_quantity_production_planning_result;
            this.TotalQtyProductionResult = data.total_quantity_production_result;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTimeTypeDetail(this.DateType);
            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;

            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.MachineChangest = this.Machine == null ? null : this.Machine.value;
            this.MachineTypeChangest = this.MachineType == null  ? null : this.MachineType.value;
            this.TypeDetailChangest = this.TypeDetail == null  ? null : this.TypeDetail.value;
            this.ItemChangest = this.Item == null  ? null : this.Item.value;
            this.DowntimeChangest = this.Downtime;

            var variables = {
                startDate: this.DateFromChangest,
                endDate: this.DateToChangest,
                machine: parseInt(this.MachineChangest),
                machineType: parseInt(this.MachineTypeChangest),
                typeDetail: this.TypeDetailChangest,
                item: this.ItemChangest,
                downtime: this.DowntimeChangest,
            };
            await this.getTotalCount(variables);

            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if ((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0) {
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else {
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                this.MachineChangest = this.Machine == '' || this.Machine == null ? null : this.Machine.value;
                this.MachineTypeChangest = this.MachineType == '' || this.MachineType == null  ? null : this.MachineType.value;
                this.TypeDetailChangest = this.TypeDetail == '' || this.TypeDetail == null  ? null : this.TypeDetail.value;
                this.ItemChangest = this.Item == '' || this.Item == null  ? null : this.Item.value;
                this.DowntimeChangest = this.Downtime;
                
                var variables = {
                    startDate: this.DateFromChangest,
                    endDate: this.DateToChangest,
                    machine: parseInt(this.MachineChangest),
                    machineType: parseInt(this.MachineTypeChangest),
                    typeDetail: this.TypeDetailChangest,
                    item: this.ItemChangest,
                    downtime: this.DowntimeChangest,
                };
                await this.getTotalCount(variables);

                this.DateType = 'Custom';
                this.gridReload++;
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if ((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0) {
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else {
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                this.MachineChangest = this.Machine == '' || this.Machine == null ? null : this.Machine.value;
                this.MachineTypeChangest = this.MachineType == '' || this.MachineType == null  ? null : this.MachineType.value;
                this.TypeDetailChangest = this.TypeDetail == '' || this.TypeDetail == null  ? null : this.TypeDetail.value;
                this.ItemChangest = this.Item == '' || this.Item == null  ? null : this.Item.value;
                this.DowntimeChangest = this.Downtime;
                
                var variables = {
                    startDate: this.DateFromChangest,
                    endDate: this.DateToChangest,
                    machine: parseInt(this.MachineChangest),
                    machineType: parseInt(this.MachineTypeChangest),
                    typeDetail: this.TypeDetailChangest,
                    item: this.ItemChangest,
                    downtime: this.DowntimeChangest,
                };
                await this.getTotalCount(variables);

                this.DateType = 'Custom';
                this.gridReload++;
            }
        },
        async onChangeDowntime() {
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.MachineChangest = this.Machine == '' || this.Machine == null ? null : this.Machine.value;
            this.MachineTypeChangest = this.MachineType == '' || this.MachineType == null  ? null : this.MachineType.value;
            this.TypeDetailChangest = this.TypeDetail == '' || this.TypeDetail == null  ? null : this.TypeDetail.value;
            this.ItemChangest = this.Item == '' || this.Item == null  ? null : this.Item.value;
            this.DowntimeChangest = this.Downtime;

            var variables = {
                startDate: this.DateFromChangest,
                endDate: this.DateToChangest,
                machine: parseInt(this.MachineChangest),
                machineType: parseInt(this.MachineTypeChangest),
                typeDetail: this.TypeDetailChangest,
                item: this.ItemChangest,
                downtime: this.DowntimeChangest,
            };
            await this.getTotalCount(variables);

            this.gridReload++;
        },
        async onChangeMachine() {
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.MachineChangest = this.Machine == '' || this.Machine == null ? null : this.Machine.value;
            this.MachineTypeChangest = this.MachineType == '' || this.MachineType == null  ? null : this.MachineType.value;
            this.TypeDetailChangest = this.TypeDetail == '' || this.TypeDetail == null  ? null : this.TypeDetail.value;
            this.ItemChangest = this.Item == '' || this.Item == null  ? null : this.Item.value;
            this.DowntimeChangest = this.Downtime;
            
            var variables = {
                startDate: this.DateFromChangest,
                endDate: this.DateToChangest,
                machine: parseInt(this.MachineChangest),
                machineType: parseInt(this.MachineTypeChangest),
                typeDetail: this.TypeDetailChangest,
                item: this.ItemChangest,
                downtime: this.DowntimeChangest,
            };
            await this.getTotalCount(variables);
            
            this.gridReload++;
        },
        async onChangeMachineType() {
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.MachineChangest = this.Machine == '' || this.Machine == null ? null : this.Machine.value;
            this.MachineTypeChangest = this.MachineType == '' || this.MachineType == null  ? null : this.MachineType.value;
            this.TypeDetailChangest = this.TypeDetail == '' || this.TypeDetail == null  ? null : this.TypeDetail.value;
            this.ItemChangest = this.Item == '' || this.Item == null  ? null : this.Item.value;
            this.DowntimeChangest = this.Downtime;
            
            var variables = {
                startDate: this.DateFromChangest,
                endDate: this.DateToChangest,
                machine: parseInt(this.MachineChangest),
                machineType: parseInt(this.MachineTypeChangest),
                typeDetail: this.TypeDetailChangest,
                item: this.ItemChangest,
                downtime: this.DowntimeChangest,
            };
            await this.getTotalCount(variables);
            
            this.gridReload++;

        },
        async onChangeTypeDetail() {
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.MachineChangest = this.Machine == '' || this.Machine == null ? null : this.Machine.value;
            this.MachineTypeChangest = this.MachineType == '' || this.MachineType == null  ? null : this.MachineType.value;
            this.TypeDetailChangest = this.TypeDetail == '' || this.TypeDetail == null  ? null : this.TypeDetail.value;
            this.ItemChangest = this.Item == '' || this.Item == null  ? null : this.Item.value;
            this.DowntimeChangest = this.Downtime;
            
            var variables = {
                startDate: this.DateFromChangest,
                endDate: this.DateToChangest,
                machine: parseInt(this.MachineChangest),
                machineType: parseInt(this.MachineTypeChangest),
                typeDetail: this.TypeDetailChangest,
                item: this.ItemChangest,
                downtime: this.DowntimeChangest,
            };
            await this.getTotalCount(variables);
            
            this.gridReload++;
            
            this.ItemData = await ProductionReportServices.getItemQuery(this.TypeDetailChangest);
            this.Item = '';
        },
        async onChangeItem() {
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.MachineChangest = this.Machine == '' || this.Machine == null ? null : this.Machine.value;
            this.MachineTypeChangest = this.MachineType == '' || this.MachineType == null  ? null : this.MachineType.value;
            this.TypeDetailChangest = this.TypeDetail == '' || this.TypeDetail == null  ? null : this.TypeDetail.value;
            this.ItemChangest = this.Item == '' || this.Item == null  ? null : this.Item.value;
            this.DowntimeChangest = this.Downtime;
            
            var variables = {
                startDate: this.DateFromChangest,
                endDate: this.DateToChangest,
                machine: parseInt(this.MachineChangest),
                machineType: parseInt(this.MachineTypeChangest),
                typeDetail: this.TypeDetailChangest,
                item: this.ItemChangest,
                downtime: this.DowntimeChangest,
            };
            await this.getTotalCount(variables);
            
            this.gridReload++;
        },
        async exportExcel() {
            this.$refs.productionReportGrid.exportExcel();
        },
    }
}
</script>

<style scoped>
</style>