<template>
    <div>           
        <kendo-grid ref="grid"
            :data-source="ProductionReport"
            :pageable="pageableConfig"
            :filterable="filterableConfig"
            :sortable="true"
            :columns=columns
            v-on:detailinit="detailInit"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>


<script>
import moment from 'moment';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { response } from '../../../../infrastructure/constant/response';
import { kendo_grid, sweet_alert, datetime_zone } from '../../../../infrastructure/constant/variable';

import productionCardReportService from '../Script/ProductionCardReportService.js';

export default {
    name: 'ProductionCardReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateFrom', 'dateTo', 'machine', 'machineType', 'typeDetail', 'item', 'productionId', 'exportExcelClick'],
    computed: {
        ProductionReport() {
            var dateFrom = this.$props.dateFrom;
            var dateTo = this.$props.dateTo;
            var machine = this.$props.machine;
            var machineType = this.$props.machineType;
            var typeDetail = this.$props.typeDetail;
            var item = this.$props.item;
            var productionId = this.$props.productionId;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom,
                                endDate: dateTo,
                                machineId: parseInt(machine),
                                machineTypeId: parseInt(machineType),
                                typeDetailId: typeDetail,
                                itemId: item,
                                productionId: productionId,
                            };
                            return {
                                query: productionCardReportService.readQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) { 
                        if(response.data.GetProductionReport == null) {
                            return [];
                        }
                        else {
                            return response.data.GetProductionReport.details;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionReport == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetProductionReport.length;
                        }
                    },
                    model: {
                        fields: {
                            total_weight_production_result: { type:"number" },
                            total_weight_waste_result: { type:"number" },
                            total_weight_loss_result: { type:"number" },
                            total_quantity_production_planning_result: { type:"number" },
                            total_quantity_production_result: {type:"number"},
                        }
                    }
                },
            });
        }
    },
    data: function(){
        return {
            columns:  [
                { title: "Action", width: 100, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "start_date", title: "Tanggal Mulai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "end_date", title: "Tanggal Selesai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_code", title: "Kode Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_code", title: "Kode Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_name", title: "Nama Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "total_quantity_production_result", title: "Quantity Barang Jadi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{style: "text-align:right;"}
                },
                { field: "total_quantity_production_planning_result", title: "Quantity Perencanaan Produksi", width: 300,
                    headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                },
                { field: "total_weight_production_result", title: "Berat Barang Jadi", width: 250,
                    headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                },
                { field: "total_weight_planning_material", title: "Berat Bahan Baku Perencanaan", width: 300, 
                    headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                },
                { field: "total_weight_waste_result", title: "Berat Waste", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{style: "text-align:right;"}
                },
                { field: "total_weight_loss_result", title: "Berat Loss", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{style: "text-align:right;"}
                },
                // { field: "notes", title: "Notes", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: async function() {     
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        var dateFrom = this.$props.dateFrom;
        var dateTo = this.$props.dateTo;
        var exportExcelClick = this.$props.exportExcelClick;

        GridElement.on("click", "#ExcelButton", function (e){
            e.preventDefault();
           
            var dataItem = grid.dataItem(e.target.closest("tr"));            
            exportExcelClick(dateFrom, dateTo, dataItem.production_id);
        })
    },
    methods: {
        columnButton(){
            var customButton = `
                <button type="button" class="btn btn-secondary btn-sm rounded" id="ExcelButton">
                    <i class="fa fa-file-excel-o"></i> </span>
                </button>
            `;
            return customButton
        },
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Bahan Baku</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "DetailMaterialListGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.production_report_detail_material);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'quantity_used_material', title: "Qty", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                    { field: 'weight_used_material', title: "Total Berat", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                ],
            })
            $('<div/>').appendTo(e.detailCell).html('<hr>');

            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Item Jadi</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "DetailProductionResultGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.production_report_detail_result);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'item_type', title: "Tipe Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'quantity_result', title: "Qty", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                    { field: 'weight_result', title: "Total Berat", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                ],
            });
        },
    }
}
</script>

<style scoped>
</style>