<template>
  
        
        <div>           
            <kendo-grid ref="grid"
                :data-source="ProductionReport"
                :pageable="pageableConfig"
                :filterable="filterableConfig"
                :sortable="true"
                :columns=columns
                v-on:detailinit="detailInit"
                :resizable="true"
            >
            </kendo-grid>
        </div>
        
    
</template>


<script>
import moment from 'moment';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { response } from '../../../../infrastructure/constant/response';
import { kendo_grid, sweet_alert, datetime_zone } from '../../../../infrastructure/constant/variable';

import productionReportService from '../Script/ProductionReportService.js';

export default {
    name: 'ProductionReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateFrom', 'dateTo', 'machine', 'machineType', 'typeDetail', 'item', 'downtime'],
    computed: {
        ProductionReport() {
            var dateFrom = this.$props.dateFrom;
            var dateTo = this.$props.dateTo;
            var machine = this.$props.machine;
            var machineType = this.$props.machineType;
            var typeDetail = this.$props.typeDetail;
            var item = this.$props.item;
            var downtime = this.$props.downtime;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom,
                                endDate: dateTo,
                                machine: parseInt(machine),
                                machineType: parseInt(machineType),
                                typeDetail: typeDetail,
                                item: item,
                                downtime: downtime,
                            };
                            return {
                                query: productionReportService.readQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) { 
                        if(response.data.GetProductionReport == null) {
                            return [];
                        }
                        else {
                            return response.data.GetProductionReport.details;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionReport == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetProductionReport.length;
                        }
                    },
                    model: {
                        fields: {
                            total_weight_production_result: { type:"number" },
                            total_weight_waste_result: { type:"number" },
                            total_weight_loss_result: { type:"number" },
                            total_quantity_production_planning_result: { type:"number" },
                            total_quantity_production_result: {type:"number"},
                            downtime: {type:"number"}
                        }
                    }
                },
            });
        }
    },
    data: function(){
        return {
            columns:  [
                { field: "type_detail_id", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "product_code", title: "Kode Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_code", title: "Kode Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_name", title: "Nama Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "start_estimation", title: "Estimasi Mulai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "start_date", title: "Tanggal Mulai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "end_estimation", title: "Estimasi Selesai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "end_date", title: "Tanggal Selesai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "total_weight_planning_material", title: "Total Berat Bahan Baku Perencanaan", width: 300, 
                    headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                },
                { field: "total_weight_production_result", title: "Total Berat Barang Jadi", width: 250,
                    headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                },
                { field: "total_weight_waste_result", title: "Total Berat Waste", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{style: "text-align:right;"}
                },
                { field: "total_weight_loss_result", title: "Total Berat Loss", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{style: "text-align:right;"}
                },
                { field: "total_quantity_production_planning_result", title: "Total Perencanaan Produksi", width: 250,
                    headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                },
                { field: "total_quantity_production_result", title: "Total Barang Jadi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{style: "text-align:right;"}
                },
                { field: "downtime", title: "Waktu Downtime", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },    
    methods: {
        async exportExcel () {
            var variables = {
                startDate : this.DateFrom,
                endDate : this.DateTo,
            };
            var gridData = this.$refs.grid.kendoWidget().dataSource._data;
            var data = productionReportService.productionReportExcel(gridData, variables);

            if(gridData.length == 0) {
                this.$swal("Error", response.noRecord, "error");
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'ProductionReport_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = productionReportService.productionReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
            
        },
        detailInit: function (e) {            
            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Bahan Baku</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "DetailMaterialListGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.production_report_detail_material);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'quantity_used_material', title: "Qty", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                    { field: 'weight_used_material', title: "Total Berat", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                ],
            })
            $('<div/>').appendTo(e.detailCell).html('<hr>');

            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Item Jadi</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "DetailProductionResultGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.production_report_detail_result);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'quantity_production_result', title: "Qty", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                    { field: 'weight_production_result', title: "Total Berat", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                ],
            });
        },
    }
}
</script>

<style scoped>
</style>