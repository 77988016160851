import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProductionCardReportService {
    readQuery(){
        var query = `
        query ($startDate:String!, $endDate:String!, $machineId:Int, $machineTypeId:Int, $itemId:String, $typeDetailId:String, $productionId:Int) {
            GetProductionReport(start_date:$startDate, end_date:$endDate, machine_id:$machineId, machine_type_id:$machineTypeId, 
                item_id:$itemId, type_detail_id:$typeDetailId, production_id:$productionId) {
                    total_weight_production_result
                    total_weight_waste_result
                    total_weight_loss_result
                    total_weight_used_material
                    total_quantity_production_planning_result
                    total_quantity_production_result
                    details {
                        machine_type_name
                        machine_type_code
                        production_id
                        item_id
                        production_code
                        item_name
                        product_code
                        type_detail_id
                        machine_code
                        machine_name
                        start_estimation
                        end_estimation
                        start_date
                        end_date
                        total_weight_planning_material
                        total_weight_used_material
                        total_weight_production_result
                        total_weight_waste_result
                        total_weight_loss_result
                        total_quantity_production_planning_result
                        total_quantity_production_result
                        total_quantity_waste_result
                        total_waste_percentage
                        downtime
                        production_report_detail_material {
                            item_name
                            product_code
                            base_uom
                            type_detail_id
                            type_detail_name
                            quantity_used_material
                            weight_used_material
                        }
                        production_report_detail_result {
                            item_id
                            item_name
                            product_code
                            item_type
                            base_uom
                            quantity_result
                            weight_result
                            secondary_conv_amount
                            secondary_uom
                            secondary_quantity_result
                            production_mutation_id
                            production_mutation_code
                            production_mutation_date
                            waste_percentage
                            base_weight
                        }
                    }
                }
            }
        `;
        return query;
    }

    async getProductionReportCountQuery(variables){
        var query = gql`
            query ($startDate: String!, $endDate: String!, $machineId:Int, $machineTypeId:Int, $itemId:String, $typeDetailId:String, $productionId:Int) {
                GetProductionReport(start_date:$startDate, end_date:$endDate, machine_id:$machineId, machine_type_id:$machineTypeId, 
                    item_id:$itemId, type_detail_id:$typeDetailId, production_id:$productionId)
                {
                    total_weight_production_result
                    total_weight_waste_result
                    total_weight_loss_result
                    total_weight_used_material
                    total_quantity_production_planning_result
                    total_quantity_production_result
                }
            }
        `;    
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProductionReport;
    }
    
    async getProductionReportDetailQuery(variables){
        var query = gql`
        query ($startDate: String!, $endDate: String!, $machineId:Int, $machineTypeId:Int, $itemId:String, $typeDetailId:String, $productionId:Int) {
            GetProductionReport(start_date:$startDate, end_date:$endDate, machine_id:$machineId, machine_type_id:$machineTypeId, 
                item_id:$itemId, type_detail_id:$typeDetailId, production_id:$productionId){
                    details {
                        machine_type_name
                        machine_type_code
                        production_id
                        item_id
                        production_code
                        item_name
                        product_code
                        type_detail_id
                        machine_code
                        machine_name
                        start_estimation
                        end_estimation
                        start_date
                        end_date
                        total_weight_planning_material
                        total_weight_used_material
                        total_weight_production_result
                        total_weight_waste_result
                        total_weight_loss_result
                        total_quantity_production_planning_result
                        total_quantity_production_result
                        total_quantity_waste_result
                        total_waste_percentage
                        downtime
                        production_report_detail_material {
                            item_name
                            product_code
                            base_uom
                            type_detail_id
                            type_detail_name
                            quantity_used_material
                            weight_used_material
                        }
                        production_report_detail_result {
                            item_id
                            item_name
                            product_code
                            item_type
                            base_uom
                            quantity_result
                            weight_result
                            secondary_conv_amount
                            secondary_uom
                            secondary_quantity_result
                            production_mutation_id
                            production_mutation_code
                            production_mutation_date
                            waste_percentage
                            base_weight
                        }
                    }
                }
            }
        `;    
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProductionReport.details[0];
    }
    
    async getProductionMasterQuery(){
        var query = gql`
            query {
                GetMasterProductions {
                    productions {
                        production_id
                        production_code
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetMasterProductions != null) {
            for (let i = 0; i < result.data.GetMasterProductions.productions.length; i++) {
                var str = { 
                    value: result.data.GetMasterProductions.productions[i].production_id, 
                    label: result.data.GetMasterProductions.productions[i].production_code
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }
    
    async getMachineQuery(){
        var query = gql`
            query {
                GetMachine {
                    machine {
                        machine_id
                        machine_code
                        machine_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetMachine != null) {
            for (let i = 0; i < result.data.GetMachine.machine.length; i++) {
                var str = { 
                    value: result.data.GetMachine.machine[i].machine_id, 
                    label: result.data.GetMachine.machine[i].machine_code + ' (' + result.data.GetMachine.machine[i].machine_name + ')'
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getMachineTypeQuery(){
        var query = gql`
            query {
                GetMachineType {
                    machine_type {
                        machine_type_id
                        machine_type_name
                        machine_type_code
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetMachineType != null) {
            for (let i = 0; i < result.data.GetMachineType.machine_type.length; i++) {
                var str = { 
                    value: result.data.GetMachineType.machine_type[i].machine_type_id, 
                    label: result.data.GetMachineType.machine_type[i].machine_type_code + ' (' + result.data.GetMachineType.machine_type[i].machine_type_name + ')'
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }
    
    async getTypeDetailQuery(){
        var query = gql`
            query {
                GetTypeDetail {
                    type_detail_id
                    type_detail_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetTypeDetail != null) {
            for (let i = 0; i < result.data.GetTypeDetail.length; i++) {
                var str = { 
                    value: result.data.GetTypeDetail[i].type_detail_id, 
                    label: result.data.GetTypeDetail[i].type_detail_name
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }
    
    async getItemQuery(typeDetailId){
        const variables = {
            typeDetail : typeDetailId
        }

        var query = gql`
            query ($typeDetail:String) {
                GetProductMaster (typeDetailID:$typeDetail) {
                    ProductMaster {
                        item_id
                        product_code
                        item_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var arrayData = [];

        if (result.data.GetProductMaster != null) {
            for (let i = 0; i < result.data.GetProductMaster.ProductMaster.length; i++) {
                var str = { 
                    value: result.data.GetProductMaster.ProductMaster[i].item_id, 
                    label: result.data.GetProductMaster.ProductMaster[i].product_code + ' (' + result.data.GetProductMaster.ProductMaster[i].item_name + ')'
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    productionCardReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
        ];

        return size;
    }

    productionCardReportExcel(data){
        var arrayObject = [];
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';
        var spaceRow = {colA : null};

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);
        
        arrayObject.push(spaceRow);

        var row2 = {colA : 'KARTU PRODUKSI',};
        arrayObject.push(row2);
        
        arrayObject.push(spaceRow);

        var row3 = {
            colA : 'KODE PRODUKSI : ',
            colB : data.product_code,
        };
        arrayObject.push(row3);

        var row4 = {
            colA : 'TANGGAL MULAI PRODUKSI : ',
            colB : data.start_date,
        };
        arrayObject.push(row4);

        var row5 = {
            colA : 'TANGGAL SELESAI PRODUKSI : ',
            colB : data.end_date,
        };
        arrayObject.push(row5);

        var row6 = {
            colA : 'KODE MESIN : ',
            colB : data.machine_code,
        };
        arrayObject.push(row6);

        var row8 = {
            colA : 'NAMA MESIN : ',
            colB : data.machine_name,
        };
        arrayObject.push(row8);

        var row9 = {
            colA : 'KODE TIPE MESIN : ',
            colB : data.machine_type_code,
        };
        arrayObject.push(row9);

        var row10 = {
            colA : 'NAME TIPE MESIN : ',
            colB : data.machine_type_name,
        };
        arrayObject.push(row10);
        
        arrayObject.push(spaceRow);

        var row11 = {
            colA : 'TANGGAL QC',
            colB : 'KODE QC',
            colC : 'KODE ITEM',
            colD : 'NAMA ITEM',
            colE : 'TIPE MUTASI',
            colF : 'QUANTITY',
            colG : 'UOM',
            colH : 'BERAT SATUAN',
            colI : 'TOTAL BERAT',
            colJ : 'PERSENTASE WASTE'
        };
        arrayObject.push(row11);

        var detailResult = data.production_report_detail_result;
        if(detailResult != null){
            for (let i = 0; i < detailResult.length; i++){
                var row = {
                    colA : detailResult[i].production_mutation_date,
                    colB : detailResult[i].production_mutation_code,
                    colC : detailResult[i].product_code,
                    colD : detailResult[i].item_name,
                    colE : detailResult[i].item_type,
                    colF : detailResult[i].quantity_result,
                    colG : detailResult[i].base_uom,
                    colH : detailResult[i].base_weight,
                    colI : detailResult[i].weight_result, //total berat (kyknya ya)
                    colJ : detailResult[i].waste_percentage
                };
                arrayObject.push(row);
            }
        }

        arrayObject.push(spaceRow);
        
        var row12 = { 
            colA : null,
            colB : null,
            colD : null,
            colE : null,
            colF : 'Quantity',
            colG : 'Berat',
        };
        arrayObject.push(row12);

        var row13 = { 
            colA : null,
            colB : null,
            colD : null,
            colE : 'TOTAL',
            colF : data.total_quantity_production_result,
            colG : data.total_weight_production_result,
        };
        arrayObject.push(row13);

        var row14 = { 
            colA : null,
            colB : null,
            colD : null,
            colE : 'TOTAL WASTE',
            colF : data.total_quantity_waste_result,
            colG : data.total_weight_waste_result,
            colH : null,
            colI : 'TOTAL PERSENTASE WASTE',
            colJ : data.total_waste_percentage
        };
        arrayObject.push(row14);
                
        arrayObject.push(spaceRow);
        arrayObject.push(spaceRow);
        
        var row15 = { 
            colA : 'BAHAN BAKU',
        };
        arrayObject.push(row15);
        
        var row16 = {
            colA : 'KODE ITEM',
            colB : 'NAMA ITEM',
            colC : 'KODE TIPE DETAIL',
            colD : 'NAMA TIPE DETAIL',
            colE : 'QUANTITY',
        };
        arrayObject.push(row16);
                
        var detailMaterial = data.production_report_detail_material;
        if(detailMaterial != null){
            for (let i = 0; i < detailMaterial.length; i++){
                var row = {
                    colA : detailMaterial[i].product_code,
                    colB : detailMaterial[i].item_name,
                    colC : detailMaterial[i].type_detail_id,
                    colD : detailMaterial[i].type_detail_name,
                    colE : detailMaterial[i].quantity_used_material,
                };
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new ProductionCardReportService();